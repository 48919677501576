/* eslint-disable */
<template>
	<div
		class="document"
	>
		<div
			v-if="!apiRes()"
			class="text-center"
		>
			<v-overlay
				:value="true"
				:opacity="1"
			>
				<v-progress-circular
					indeterminate
					size="64"
				/>
			</v-overlay>
		</div>
		<div
			v-else
			class="mainContant"
		>
			<v-container v-if="page.apiRes">
				<div
					v-if="page.document.termsCondition"
					id="terms-conditions"
				>
					<v-row>
						<v-col cols="12">
							<p class="display-1 primary--text">
								Terms & Conditions
							</p>
						</v-col>
						<v-col cols="12">
							<p
								class="font-weight-light"
							>
								<!-- eslint-disable-next-line vue/no-v-html -->
								<span v-html="page.document.termsCondition" />
							</p>
						</v-col>
					</v-row>
					<v-divider class="my-8" />
				</div>

				<div
					v-if="page.document.privacyPolicy"
					id="privacy-policy"
				>
					<v-row>
						<v-col cols="12">
							<p class="display-1 primary--text">
								Privacy Policy
							</p>
						</v-col>
						<v-col cols="12">
							<p
								class="font-weight-light"
							>
								<!-- eslint-disable-next-line vue/no-v-html -->
								<span v-html="page.document.privacyPolicy" />
							</p>
						</v-col>
					</v-row>
					<v-divider class="my-8" />
				</div>

				<div
					v-if="page.document.bankDetails"
					id="bank-detail"
				>
					<v-row>
						<v-col cols="12">
							<p class="display-1 primary--text">
								Bank Detail
							</p>
						</v-col>
						<v-col cols="12">
							<p
								class="font-weight-light"
							>
								<!-- eslint-disable-next-line vue/no-v-html -->
								<span v-html="page.document.bankDetails" />
							</p>
						</v-col>
					</v-row>
				</div>
			</v-container>

			<v-container v-else>
				<div>
					<v-row>
						<v-col
							col="12"
						>
							<v-skeleton-loader
								v-for="n in 20"
								:key="n"
								width="100%"
								type="paragraph"
								elevation="2"
								class="mb-6"
							/>
						</v-col>
					</v-row>
				</div>
			</v-container>
		</div>
	</div>
</template>

<script>
import { api, baseMixins } from "mixins";

export default {
	mixins: [baseMixins, api],
	data() {
		return {
			page: {
				apiRes: false,
				document: {},
			},
		};
	},
	created() {
		this.getDocument();
		window.scrollTo(0, 0);
	},
	mounted() {
		this.scrollToAnchor();
	},

	updated() {
		this.scrollToAnchor();
	},
	methods: {
		scrollToAnchor() {
			this.$nextTick(() => {
				if (this.$route.hash) {
					const $el = document.querySelector(this.$route.hash);
					if ($el) {
						window.scrollTo(0, $el.offsetTop);
					}
				}
			});
		},
		apiRes() {
			return this.page.apiRes;
		},
		getDocument() {
			if (this.baseCheckBeforeCallApiFunction()) {
				this.page.apiRes = false;
				this.callApi(this.$axios, "f/document/get", {}, { apiKey: "ev-f-do-get" }).then((response) => {
					this.page.document = response;
					this.page.apiRes = true;
				});
			}
		},
	},
};
</script>
